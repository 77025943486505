<nav mat-tab-nav-bar style="background-color: white;">
  <div class="matero-topmenu">
    <ng-container *ngFor="let menuItem of menuItems">
      <button mat-button [routerLink]="[menuItem.path]">
        <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
        <span class="menu-label">{{menuItem.title}}</span>
      </button>
    </ng-container>
  </div>
</nav>



