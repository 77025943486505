<ul class="matero-sidemenu level-0 ">
  <ng-container *ngFor="let menuItem of menuItems">
      <li class="matero-sidemenu-item custom-dark" routerLinkActive="active"

      >

        <a class="matero-sidemenu-link"
          [routerLink]="[menuItem.path]"
        >
          <mat-icon class="menu-icon">{{menuItem.icon}}</mat-icon>
          <!-- <fa-icon class="menu-icon" [icon]="['far', $any(menuItem.icon) ]"></fa-icon> -->
          <span class="matero-sidemenu level-1">{{menuItem.title}}</span>

        </a>

      </li>
  </ng-container>
</ul>
