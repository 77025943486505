<page-header class="p-t-24 p-b-32 m-b--24"
             title="Dashboard" hideBreadcrumb>
</page-header>


<div class="matero-row" fxLayout="row wrap">
  <!-- Mini Cards -->
  <!-- First Card - Total Amount Passive Summary  -->
  <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" >
    <mat-card class="text-white bg-indigo-500 card-height">
      <mat-card-title class="f-s-12 f-w-100">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <p class="m-y-0">Totale fatture ricevute</p>
          <div class="checkbox-wrapper-10">
            <input class="tgl tgl-flip" id="cb1" type="checkbox" [(ngModel)]="passiveSummThisYear" />
            <label class="tgl-btn" [attr.data-tg-off]="lastYearString" [attr.data-tg-on]="thisYearString" for="cb1"></label>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayoutAlign="space-between start">
          <ng-container *ngIf="passiveSummThisYear == true else lastYear">
            <h1 class="f-w-100 m-y-0">{{docsSummaries.passiveDocumentsSummaryThisYear | customcurrency }}</h1>
          </ng-container>
          <ng-template #lastYear>
            <h1 class="f-w-100 m-y-0">{{docsSummaries.passiveDocumentsSummaryLastYear | customcurrency }}</h1>
          </ng-template>
        </div>
        <!-- <small class="text-muted">Monthly</small> -->
      </mat-card-content>
    </mat-card>
  </div>
  <!-- Second Card - Total Amount Active Summary  -->
  <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100">
    <mat-card class="text-white bg-blue-500 card-height">
      <mat-card-title class="f-s-12 f-w-100">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <p class="m-y-0">Totale fatture emesse</p>
          <div class="checkbox-wrapper-10">
            <input class="tgl tgl-flip" id="cb2" type="checkbox" [(ngModel)]="activeSummThisYear" />
            <label class="tgl-btn" [attr.data-tg-off]="lastYearString" [attr.data-tg-on]="thisYearString" for="cb2"></label>
          </div>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayoutAlign="space-between start">
          <ng-container *ngIf="activeSummThisYear == true else activelastYear">
            <h1 class="f-w-100 m-y-0">{{docsSummaries.activeDocumentSummaryThisYear | customcurrency }}</h1>
          </ng-container>
          <ng-template #activelastYear>

            <h1 class="f-w-100 m-y-0">{{docsSummaries.activeDocumentSummaryLastYear | customcurrency }}</h1>
          </ng-template>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- Third Card - Total Payment Dues Summary  -->
  <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100" *ngIf="handlesPassivePaymentDues == true">
    <mat-card class="text-white bg-green-500 card-height">
      <mat-card-title class="f-s-12 f-w-100">
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <p class="m-y-0">Totale scadenze da pagare</p>
          <p class="m-y-0">Anno in corso</p>
        </div>
      </mat-card-title>
      <mat-card-content>
        <div fxLayout="row" fxLayoutAlign="space-between start">
          <h1 class="f-w-100 m-t-8">{{docsSummaries.passivePaymentDuesSummaryThisYear | customcurrency }}</h1>
          <!-- <h1 class="f-w-100 m-y-0">{{docsCount.thisMonthPaymentDuesCount}}</h1> -->
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="matero-col" fxFlex.gt-sm="25" fxFlex.gt-xs="50" fxFlex="100">
    <mat-card class="text-white bg-teal-500 card-height">
      <mat-card-title class="f-s-12 f-w-100">Documenti aggiornati al</mat-card-title>
      <mat-card-content>
        <h1 class="f-w-100 m-t-16">{{lastFetch?.lastExecution | date: 'medium' }}</h1>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Charts -->
  <div class="matero-col" fxFlex="100" fxFlex.gt-sm="50">
    <mat-card >
      <mat-card-subtitle>Principali Fornitori anno in corso</mat-card-subtitle>
      <app-top-suppliers-chart></app-top-suppliers-chart>
    </mat-card>
  </div>
  <div class="matero-col" fxFlex="100" fxFlex.gt-sm="50" *ngIf="handlesPassivePaymentDues == true">
    <mat-card >
      <mat-card-subtitle>Scadenze da pagare (anno in corso)</mat-card-subtitle>
      <app-payment-dues-chart></app-payment-dues-chart>
    </mat-card>
  </div>

  <!-- Table -->
  <div class="matero-col" fxFlex="100" >
    <app-last-documents-table></app-last-documents-table>
  </div>


</div>

