import { Injectable } from '@angular/core';
import {EnvironmentsService} from "@core/services/environments.service";

@Injectable({
  providedIn: 'root'
})
export class EndpointDictionaryService {

  private baseUrl: string = "";
  private endpointDictionary: Map<string,string>;

  constructor(private env: EnvironmentsService ) {
    this.env.Environment$.subscribe((res) => {
      this.baseUrl = res.apiURL;
    });

    this.endpointDictionary = new Map<string,string>();
    //INVOICES
    this.endpointDictionary.set('GetInvoiceById','invoice');
    this.endpointDictionary.set('InvoiceSearch','invoice/search');
    this.endpointDictionary.set('SetInvoiceViewed','invoice');
    this.endpointDictionary.set('InsertOrUpdatePaymentDues','paymentDues/listUpdate');
    this.endpointDictionary.set('InvoiceSendEmail','invoice/sendEmail');
    this.endpointDictionary.set('CheckSendEmail','invoice/checkSendingEmail');
    this.endpointDictionary.set('NotHandledByInvoiceIdList','paymentDues/setNotHandledByInvoiceIdList');
    //PAYMENTDUES
    this.endpointDictionary.set('GetPaymentDues','paymentDues');
    this.endpointDictionary.set('UpdatePaymentDue','paymentDues/update');
    this.endpointDictionary.set('InsertOrUpdatePayment','payment/update');
    this.endpointDictionary.set('DeletePayment','payment/delete');
    //PAYMENTTYPES
    this.endpointDictionary.set('GetPaymentTypes','paymentType/all');
    //PAYMENTACCOUNTS
    this.endpointDictionary.set('GetPaymentAccounts','paymentAccount/all');
    this.endpointDictionary.set('GetPaymentAccountsByName','paymentAccount/byAccountName/');
    this.endpointDictionary.set('InsertOrUpdatePaymentAccount','paymentAccount/update');
    this.endpointDictionary.set('DeletePaymentAccount','paymentAccount/delete/');
    //EMAILPROPERTIES
    this.endpointDictionary.set('GetEmailProperties','emailProps');
    this.endpointDictionary.set('SetEmailProperties','emailProps');
    //ANAGRAFICA
    this.endpointDictionary.set('GetAnagrafica','anagrafica');
    this.endpointDictionary.set('SetAnagrafica','anagrafica');
    //USERS
    this.endpointDictionary.set('Authenticate','users/authenticate');
    this.endpointDictionary.set('GetPdf','invoice/pdf');
    //HOME DASHBOARD
    this.endpointDictionary.set('GetDocumentsSummaries','homestats/summaries');
    this.endpointDictionary.set('GetLastDocs','homestats/lastdocs');
    this.endpointDictionary.set('GetTopSuppliersThisYear','homestats/topsuppliersthisyear');
    this.endpointDictionary.set('GetTopSuppliersLastYear','homestats/topsupplierslastyear');
    this.endpointDictionary.set('GetPaymentsSummaryThisYear','homestats/paymentdues/monthlythisyear');
    this.endpointDictionary.set('GetLastExecutions','homestats/getlastexecutions');
    //SETTINGS
    this.endpointDictionary.set('GetBackendVersion','version');
    this.endpointDictionary.set('GetCompanySettings','companysettings/getall');
    this.endpointDictionary.set('SetCompanySettings','companysettings/update');

  }

  private getBaseUrl(){
    return this.baseUrl;
  }
  getServiceBaseUrl(key: string){
    return this.getBaseUrl()+this.endpointDictionary.get(key);
  }





}
