import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndpointDictionaryService } from '@core/services/endpoint-dictionary.service';
import { HomeDocumentsSummaryDTO, HomeMonthlyPaymentDuesSummaryDTO, HomeTopCompanyDTO } from '@shared/dto/homestatsdto';
import { InvoiceHeaderDTO } from '@shared/dto/invoiceheaderdto';
import { TaskLastExecution } from '@shared/models/tasklastexecution';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

constructor(private http: HttpClient, private dictService: EndpointDictionaryService) { }

  getDocumentsSummaries(): Observable<HomeDocumentsSummaryDTO>{
    return this.http.get<HomeDocumentsSummaryDTO>(this.dictService.getServiceBaseUrl('GetDocumentsSummaries'));
  }

  getLastDocuments(): Observable<InvoiceHeaderDTO[]>{
    return this.http.get<InvoiceHeaderDTO[]>(this.dictService.getServiceBaseUrl('GetLastDocs'));
  }

  getTopSuppliersThisYear(): Observable<HomeTopCompanyDTO[]>{
    return this.http.get<HomeTopCompanyDTO[]>(this.dictService.getServiceBaseUrl('GetTopSuppliersThisYear'));
  }

  getTopSuppliersLastYear(): Observable<HomeTopCompanyDTO[]>{
    return this.http.get<HomeTopCompanyDTO[]>(this.dictService.getServiceBaseUrl('GetTopSuppliersLastYear'));
  }

  getPaymentDuesSummaries(): Observable<HomeMonthlyPaymentDuesSummaryDTO[]>{
    return this.http.get<HomeMonthlyPaymentDuesSummaryDTO[]>(this.dictService.getServiceBaseUrl('GetPaymentsSummaryThisYear'));
  }

  getLastExecutions () : Observable<TaskLastExecution[]> {
    return this.http.get<TaskLastExecution[]>(this.dictService.getServiceBaseUrl('GetLastExecutions'));
  }
}
