import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import {CustomCurrencypipe} from "@shared/utils/custom.currencypipe";
import { NgApexchartsModule } from 'ng-apexcharts';
import { SkeletonRectComponent } from './components/skeleton-rect/skeleton-rect.component';
import {CoolcalendarComponent} from './components/coolcalendar/coolcalendar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faFilePdf as farFilePdf,
  faPencil as farPencil,
  faSpinner as farSpinner,
  faUndo as farUndo,
  faSave as farSave,
  faTimes as farTimes,
  faCheck as farCheck,
  faSortDown as farSortDown,
  faEdit as farEdit,
  faTrashAlt as farTrashAlt,
  faUndoAlt as farUndoAlt,
  faCaretDown as farCaretDown,
  faCaretLeft as farCaretLeft,
  faCaretRight as farCaretRight,
  faEnvelope as farEnvelop,
  faCircle as farCircle,
  faCheckCircle as farCheckCircle,
  faTrash as farTrash,
  faHouse as farHouse,
  faFileInvoice as farFileInvoice,
  faSearch as farSearch,
  faAngleLeft as fasAngleLeft
} from '@fortawesome/pro-regular-svg-icons';
import {SpinnerComponent} from "@shared/spinner/spinner.component";
import {MatDatepickerIntl} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const MODULES: any[] = [
  CommonModule,
  RouterModule,
  ReactiveFormsModule,
  FormsModule,
  DragDropModule,
  MaterialModule,
  FlexLayoutModule,
  NgApexchartsModule,
  FontAwesomeModule
];
const COMPONENTS: any[] = [PageHeaderComponent, SkeletonRectComponent, CoolcalendarComponent, SpinnerComponent];
const COMPONENTS_DYNAMIC: any[] = [];
const DIRECTIVES: any[] = [];
const PIPES: any[] = [CustomCurrencypipe];

@NgModule({
    imports: [...MODULES],
    exports: [...MODULES, ...COMPONENTS, ...DIRECTIVES, ...PIPES],
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC, ...DIRECTIVES, ...PIPES],
    providers: [    { provide: LOCALE_ID, useValue: "it-IT" },
      {provide: MatDatepickerIntl},
      {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
      {
        provide: DateAdapter,
        useClass: MomentDateAdapter,
        deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
      },
      { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(farFilePdf,farPencil,farSpinner,farUndo,
      farSave,farTimes,farCheck,farSortDown, farEdit,farTrashAlt,
      farUndoAlt,farCaretDown,farCaretLeft,farCaretRight,farEnvelop,
      farCircle, farCheckCircle, farTrash, farHouse, farFileInvoice,farSearch, fasAngleLeft);
  }
}
