import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceHeaderDTO } from '@shared/dto/invoiceheaderdto';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'app-last-documents-table',
  template: `
    <mat-card>
      <mat-card-subtitle>Ultimi documenti ricevuti</mat-card-subtitle>
      <mat-card-content>
        <table class="w-full" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="isPassive">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element;">
              <div class="border-passive" *ngIf="element.isPassive" color="warn">ricevuta</div>
              <div class="border-active" *ngIf="!element.isPassive" color="primary">emessa</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="documentDate">
            <th mat-header-cell *matHeaderCellDef> Data doc </th>
            <td mat-cell *matCellDef="let element;"> {{element.documentDate | date: 'dd/MM/yy'}} </td>
          </ng-container>
          <ng-container matColumnDef="documentNumber">
            <th mat-header-cell *matHeaderCellDef> Num. Documento </th>
            <td mat-cell *matCellDef="let element;"> {{element.documentNumber}} </td>
          </ng-container>
          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef> Denominazione </th>
            <td mat-cell *matCellDef="let element;"> {{element.companyName}} </td>
          </ng-container>
          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell *matHeaderCellDef> Importo </th>
            <td mat-cell *matCellDef="let element;"> {{element.totalAmount | customcurrency }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.f-w-500]='row.notViewed'></tr>
        </table>
      </mat-card-content>
    </mat-card>
  `,
  styles: [`
    .mat-header-cell {
      flex-direction: column;
      // justify-content: center;
      background-color: #EEEEEE
    }
    .mat-row:nth-child(even){
      background-color: #FAFAFA;
    }
    .mat-row:nth-child(odd){
      background-color: transparent;
    }
    .mat-column-actions {
      text-align: center;
    }
    .mat-column-isPassive {
      text-align: left;
      padding-right: 5px;
    }
    .mat-column-totalAmount {
      text-align: right;
    }
    .icon-action {
      color: #9E9E9E;
    }
    .mat-header-row, .mat-row {
      height: 42px !important;

    }
    .border-passive {
      border-radius: 5px;
      width: 4.3em;
      height: 1.3em;
      padding: 0.05em;
      background: #fff;
      border: 0.1em solid #4CAF50;
      color: #4CAF50;
      text-align: center;
      font: 18px Arial, sans-serif;
    }
    .border-active {
      border-radius: 5px;
      width: 4.3em;
      height: 1.3em;
      padding: 0.05em;
      background: #fff;
      border: 0.1em solid #2196F3;
      color: #2196F3;
      text-align: center;
      font: 18px Arial, sans-serif;
    }
  `
  ]
})
export class LastDocumentsTableComponent implements OnInit {

  // TABLE
  lastDocs: InvoiceHeaderDTO[];
  displayedColumns: string[] = ['isPassive', 'documentNumber' ,'documentDate', 'companyName', 'totalAmount'];
  dataSource: MatTableDataSource<InvoiceHeaderDTO>;

  constructor(private dashboardService: DashboardService,) { }

  ngOnInit(): void {
    this.dashboardService.getLastDocuments().subscribe((resp: InvoiceHeaderDTO[]) =>{
      this.lastDocs = resp;
      this.dataSource = new MatTableDataSource<InvoiceHeaderDTO>(this.lastDocs);
    });
  }

}
