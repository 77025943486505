import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

interface Environment {
  apiURL: string;
  mode: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentsService {

  private readonly ENV_URL = 'assets/environments.json';
  public Environment$: Observable<Environment>;

  constructor(private http: HttpClient) { }


  public loadEnvironments(): any {
    if (!this.Environment$) {
      this.Environment$ = this.http.get<Environment>(this.ENV_URL).pipe(
        shareReplay(1)
      );
    }
    return this.Environment$;
  }

}

