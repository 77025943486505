import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuList } from '@core/menuinfo';


@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidemenuComponent implements OnInit {
  // NOTE: Ripple effect make page flashing on mobile
  @Input() ripple = false;

  public menuItems: any[];

  constructor() {}

  ngOnInit(): void {
    this.menuItems = MenuList.filter(menuItem => menuItem);
  }
}


// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sidemenu',
//   templateUrl: './sidemenu.component.html',
//   styleUrls: ['./sidemenu.component.scss']
// })
// export class SidemenuComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }
