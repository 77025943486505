import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/authentication/auth.service';
import { User } from '@core/authentication/user';


// import { AuthService, User } from '@core/authentication';

@Component({
  selector: 'app-user',
  template: `
    <button
      class="matero-toolbar-button matero-avatar-button"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      <img class="matero-avatar" src="assets/images/users/2.png" width="32" alt="avatar" />
      <span class="matero-username" fxHide.lt-sm>{{getUserInitials()}}</span>
    </button>

    <mat-menu #menu="matMenu">
      <button routerLink="/settings/profile" mat-menu-item>
        <mat-icon>account_circle</mat-icon>
        <span>Profilo</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  `,
})
export class UserComponent implements OnInit {
  user!: User;



  constructor(private auth: AuthService) {

  }

  ngOnInit(): void {
    this.auth.user.subscribe(x => this.user = x);
  }

  logout() {
    this.auth.logout();
  }

  getUserInitials() {
    const firstNameInitial = this.user.firstName.charAt(0);
    const lastNameInitial = this.user.lastName.charAt(0);
    return firstNameInitial.toUpperCase() + lastNameInitial.toUpperCase();
  }

  getUserInfo() {
    return this.user.firstName + ' ' + this.user.lastName
  }
}
