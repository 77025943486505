import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { AuthService } from '@core/authentication/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
          if ([401, 403].includes(err.status) && this.authService.userValue) {
            // auto logout if 401 or 403 response returned from api
            this.authService.logout();
          }

          if ([500].includes(err.status)) {
            // Error response from apis
            console.error(err);
            return throwError(err.error);
          }

          if (err.status === 0 && err.error instanceof ProgressEvent) {
            // A client-side or network error occurred.
            console.log('Client side or network error:', err.error)
            return throwError('Errore di comunicazione con il server');
          }

          const error = err.error?.message || err.statusText;
          console.error(err);
          return throwError(error);
        }))
    }
}
