import { animate } from '@angular/animations';
import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HomeTopCompanyDTO } from '@shared/dto/homestatsdto';
import * as ApexCharts from 'apexcharts';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import { DashboardService } from './dashboard.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Component({
  selector: 'app-top-suppliers-chart',
  template: `
    <div id="chart">

    </div>
  `,
  styles: [`
    #chart {
      max-width: 500px;
      margin: auto auto;
      padding: 0;
    }`
  ]
})
export class TopSuppliersChartComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() thisYear = true;

  chart: any;
  public chartOptions: Partial<ChartOptions> | any;
  topSuppliers: HomeTopCompanyDTO[];

  constructor(
    private ngZone: NgZone,
    private dashboardService: DashboardService) {
    this.chartOptions = {
      series: [],
      chart: {
        type: "donut"
      },
      labels: [],
      plotOptions: {
        pie: {
          customScale: 1,
          donut: {
            size: '35%'
          }
        }
      },
      legend: {
        width: 210,
        itemMargin: {
          horizontal: 5,
          vertical: 0
        }
      },
      // tooltip: {
      //   enabled: false
      // },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {

    this.dashboardService.getTopSuppliersThisYear().subscribe((resp: HomeTopCompanyDTO[]) =>{
      this.topSuppliers = resp;
      this.chartOptions.series = this.topSuppliers.map(a => a.totalAmount);
      this.chartOptions.labels = this.topSuppliers.map(a => a.companyName.substring(0, 20));
      this.chart.updateOptions (this.chartOptions, true, animate, true);
    });

  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => this.initChart());
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart?.destroy();
    }
  }

  initChart() {
    this.chart = new ApexCharts(document.querySelector('#chart'), this.chartOptions);
    this.chart?.render();
  }

}
