import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { MaindashModule } from './maindash/maindash.module';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentsService } from '@core/services/environments.service';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { APP_BASE_HREF, DatePipe, registerLocaleData } from "@angular/common";
import localeIt from '@angular/common/locales/it';
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomMatpaginatorIt } from "@shared/utils/custom.matpaginator";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    DatePipe,
    BrowserModule,
    HttpClientModule,
    MaindashModule,
    PagesModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot(),
    MatMomentDateModule,
    MatDatepickerModule
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/InvoiceManagerMat'},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
    {provide: MatPaginatorIntl, useClass: CustomMatpaginatorIt},
    EnvironmentsService,
    {
      provide: APP_INITIALIZER,
      useFactory: (EnvironmentsService: EnvironmentsService) => () => EnvironmentsService.loadEnvironments().toPromise(),
      deps: [EnvironmentsService],
      multi: true
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor() {
    registerLocaleData(localeIt, 'it');
  }
}
