import { Component, OnInit } from '@angular/core';
import { User } from '@core/authentication/user';



@Component({
  selector: 'app-user-panel',
  template: `
    <div class="matero-user-panel" fxLayout="column" fxLayoutAlign="center center">
      <img class="matero-user-panel-avatar" src="assets/images/users/2.png" alt="avatar" width="64" />
      <h4 class="matero-user-panel-name">Henrique</h4>
      <h5 class="matero-user-panel-email">harismen@gmail.com</h5>
      <div class="matero-user-panel-icons">
        <a routerLink="/settings/profile" mat-icon-button>
          <mat-icon class="icon-18">account_circle</mat-icon>
        </a>
        <a routerLink="/settings/profile
        " mat-icon-button>
          <mat-icon class="icon-18">settings</mat-icon>
        </a>
        <a (click)="logout()" mat-icon-button>
          <mat-icon class="icon-18">exit_to_app</mat-icon>
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit {
  user!: User;

  constructor() {}

  ngOnInit(): void {
    // this.auth.user().subscribe(user => (this.user = user));
  }

  logout() {
    // this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }
}





