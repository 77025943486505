import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { LoginComponent } from './sessions/login/login.component';
import { TopSuppliersChartComponent } from './dashboard/top-suppliers-chart.component';
import { LastDocumentsTableComponent } from './dashboard/last-documents-table.component';
import { PaymentDuesChartComponent } from './dashboard/payment-dues-chart.component';


@NgModule({
  declarations: [
    DashboardComponent,
    LoginComponent,
    TopSuppliersChartComponent,
    LastDocumentsTableComponent,
    LoginComponent,
    PaymentDuesChartComponent
  ],
  imports: [SharedModule, AppRoutingModule]
})
export class PagesModule { }
