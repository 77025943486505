import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarNoticeComponent } from './sidebar-notice/sidebar-notice.component';
import { TopmenuComponent } from './topmenu/topmenu.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { BrandingComponent } from './widgets/branding.component';
import { NotificationComponent } from './widgets/notification.component';
import { TranslateComponent } from './widgets/translate.component';
import { UserComponent } from './widgets/user.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { CustomizerComponent } from './customizer/customizer.component';
import { UserPanelComponent } from './sidebar/user-panel.component';
import { SharedModule } from '@shared/shared.module';




@NgModule({
  declarations: [
    AdminLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarNoticeComponent,
    TopmenuComponent,
    AuthLayoutComponent,
    BrandingComponent,
    NotificationComponent,
    TranslateComponent,
    UserComponent,
    SidemenuComponent,
    CustomizerComponent,
    UserPanelComponent

  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class MaindashModule { }
