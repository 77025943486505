import {Injectable, OnInit} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService implements OnInit{
  private count = 0;
  private spinner$ = new BehaviorSubject<string>('');

  constructor() {
  }

  ngOnInit() {
  }

  getSpinnerObserver(): Observable<string>{
    return this.spinner$.asObservable();
  }

  requestStarted(){
    if(++this.count === 1){
      this.spinner$.next('start');
    }
  }

  requestEnded(){
    if (this.count === 0 || --this.count === 0 ) {
      this.spinner$.next('stop');
    }
  }

  resetSpinner() {
    this.count = 0;
    this.spinner$.next('stop');
  }

}
