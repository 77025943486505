import { animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { HomeMonthlyPaymentDuesSummaryDTO } from '@shared/dto/homestatsdto';
import { DashboardService } from './dashboard.service';




export type Chart2Options = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-payment-dues-chart',
  template: `
    <div style="text-align:center" class="chart2" id="chart2">
      <apx-chart
        [series]="chart2Options.series"
        [chart]="chart2Options.chart"
        [xaxis]="chart2Options.xaxis"
        [title]="chart2Options.title"
      ></apx-chart>
    </div>
  `,
  styles: [`
  #chart2 {
    max-width: 800px;
    margin: auto auto;
    padding: 0;
  }`
]
})

export class PaymentDuesChartComponent implements OnInit {

  @ViewChild("chart2") chart: PaymentDuesChartComponent;

  chart2: any;
  public chart2Options: Partial<Chart2Options> | any;
  paymentDuesSummary: HomeMonthlyPaymentDuesSummaryDTO[];

  constructor(
    private dashboardService: DashboardService) {
      this.chart2Options = {
        series: [
          {
            name: "My-series",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
          }
        ],
        chart: {
          height: 240,

          type: "bar"
        },
        xaxis: {
          categories: ["Jan", "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug", "Sep"],
          plotOptions: {
            bar: {
              dataLabels: {
                position: "top" // top, center, bottom
              }
            }
          }
        },
        yaxis: {
          show: false,
          showAlways: false,
          showForNullSeries: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              }
            }
          }
        ],
      // tooltip: {
      //   enabled: false
      // },
      };
  }

  ngOnInit(): void {

    this.dashboardService.getPaymentDuesSummaries().subscribe((resp: HomeMonthlyPaymentDuesSummaryDTO[]) =>{
      this.paymentDuesSummary = resp;
      this.updateSeries(this.paymentDuesSummary);
      // this.chart2.updateSeries([{
      //   name: 'PaymentDues',
      //   data: this.paymentDuesSummary.map(a => a.paymentDuesSummaryAmount)
      // }]);
      // this.chart2Options.series = this.paymentDuesSummary.map(a => a.paymentDuesSummaryAmount);
      // this.chart2Options.xaxis.categories = this.paymentDuesSummary.map(a => a.month);
      // this.chart2.updateOptions (this.chart2Options, true, animate, true);
    });

  }

  public updateSeries(data: any) {
    let series = data.map(a => a.paymentDuesSummaryAmount);
    let catNumbers = data.map(a => a.month);
    let categories = catNumbers.map(b => this.getMonthName(b));

    this.chart2Options.series = [{
      name: 'Totale Scadenze',
      data: series
    }];

    this.chart2Options.xaxis = {
      categories: categories
    };

    this.chart2Options.chart = {
      height: 230,
      type: "area",
      toolbar: {
        show: false,
      }
    };



  }

  private getMonthName(monthNumber) {
    // const date = new Date();
    // date.setMonth(monthNumber - 1);

    // return date.toLocaleString('it-IT', { month: 'long' });

    var monthNames = [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
                       'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ];
    return monthNames[monthNumber - 1];

  }





}
