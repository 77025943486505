import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/authentication/auth.guard';
import { AdminLayoutComponent } from './maindash/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './maindash/auth-layout/auth-layout.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/sessions/login/login.component';

// const routes: Routes = [

//   { path: '', loadChildren: () => import('./maindash/maindash.module').then(m => m.MaindashModule) },
//   { path: '**', redirectTo: '' }

// ];

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    // canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      // { path: '403', component: Error403Component },
      // { path: '404', component: Error404Component },
      // { path: '500', component: Error500Component },
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'helpers',
        loadChildren: () => import('./pages/helpers/helpers.module').then(m => m.HelpersModule),
      },
      {
        path: 'invoices',
        loadChildren: () => import('./pages/invoices/invoices.module').then(m => m.InvoicesModule),
      },
      {
        path: 'deadlines',
        loadChildren: () => import('./pages/deadlines/deadlines.module').then(m => m.DeadlinesModule),
      },
    ],
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      //{ path: 'register', component: RegisterComponent },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
