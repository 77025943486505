export interface MenuInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const MenuList: MenuInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon:'dashboard', class: 'menu-button' },
  { path: '/invoices/passive', title: 'Fatture Ricevute', icon:'file_copy', class: 'menu-button' },
  { path: '/invoices/active', title: 'Fatture Emesse', icon:'library_books', class: 'menu-button' },
  { path: '/deadlines', title: 'Scadenze e Pagamenti', icon:'filter_none', class: 'menu-button' },
  { path: '/settings', title: 'Impostazioni', icon:'settings', class: 'menu-button' },

];
