import { Component, OnInit } from '@angular/core';
import { MenuList } from '@core/menuinfo';

@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.scss']
})
export class TopmenuComponent implements OnInit {

  public menuItems: any[];

  constructor() { }

  ngOnInit(): void {
    this.menuItems = MenuList.filter(menuItem => menuItem);
  }

}
