import { Component, ElementRef, OnInit } from '@angular/core';
import { CompanySettingsService } from '@core/services/company-settings.service';
import { CompanySettingDTO } from '@shared/dto/companysettingdto';
import { HomeDocumentsSummaryDTO, HomeLastExecutionDTO } from '@shared/dto/homestatsdto';
import { TaskLastExecution } from '@shared/models/tasklastexecution';
import * as moment from 'moment';
import { DashboardService } from './dashboard.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  // COMPANY SETTINGS
  public companySettings: CompanySettingDTO[];
  handlesPassivePaymentDues: boolean = false;

  // CARDS
  docsSummaries: HomeDocumentsSummaryDTO = {
    passiveDocumentsSummaryThisYear: 0,
    activeDocumentSummaryThisYear: 0,
    passivePaymentDuesSummaryThisYear: 0,
    passiveDocumentsSummaryLastYear: 0,
    activeDocumentSummaryLastYear: 0,
  }

  passiveSummThisYear: boolean = true;
  activeSummThisYear: boolean = true;

  public thisYearString = 'this';
  public lastYearString = 'that';

  lastFetch?: TaskLastExecution = {
    id: 0,
    taskName: '',
    lastExecution: new Date(new Date().getFullYear(), 0, 1),
    createdByUserId: undefined,
    createdDate: new Date(new Date().getFullYear(), 0, 1),
    lastModifiedUserId: undefined,
    lastModifiedDate: undefined,
    isActive: false,
    isDeleted: false
  };

  lastExecutions: TaskLastExecution[] = [];

  isLoading: boolean = true;

  constructor(
    private dashboardService: DashboardService,
    private settingsService: CompanySettingsService,
    ) {

    }

  ngOnInit(): void {
    this.isLoading = true;
    this.getCompanySettings();
    this.getStats();


    this.lastYearString = moment().subtract(1, "year").format("YYYY");
    this.thisYearString = moment(new Date()).format('YYYY');
  }


  getStats () {
    this.dashboardService.getDocumentsSummaries().subscribe((resp: HomeDocumentsSummaryDTO) =>{
      this.docsSummaries = resp;
    });

    this.dashboardService.getLastExecutions().subscribe((resp: TaskLastExecution[]) =>{
      this.lastExecutions = resp;
      this.lastFetch = this.lastExecutions.find((obj) => {
        return obj.taskName === 'INVOICE_FEEDER';
      });
      console.log(this.lastExecutions);
    });

    this.isLoading = false;
  }

  getCompanySettings () {
    this.settingsService.getCompanySettings().subscribe((resp: CompanySettingDTO[]) => {
      this.companySettings = resp;

      if(resp != undefined ) {
        for (var prop of resp) {
          switch (prop.nameProperty) {
            case 'IsHandlingPassivePaymentDues':
              this.handlesPassivePaymentDues = (prop.valueProperty === 'true');
              break;
            default:
              //default block statement;
          }
        }
      };
    });
  }

}
