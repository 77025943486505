import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentsService } from '@core/services/environments.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './user';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  apiURL: string;
  mode: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private envService: EnvironmentsService) {
      // Environments Variable read
      this.envService.Environment$.subscribe(res => {
        this.apiURL = res.apiURL.replace("api/","");
        this.mode = res.mode;
    });

    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(username: string, password: string) {

    return this.http.post<User>(`${this.apiURL}users/authenticate`, {username,password})
    .pipe(map(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('user', JSON.stringify(user));
      this.userSubject.next(user);
      return user;
    }));
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null!);
    this.router.navigate(['auth/login']);
  }

  register(user: User) {
    console.log(user);
    return this.http.post(`${this.apiURL}users/register`, user);
  }

  getAll() {
    return this.http.get<User[]>(`${this.apiURL}users`);
  }

  getById(id: string) {
    return this.http.get<User>(`${this.apiURL}users/${id}`);
  }

  update(id: string, params: any) {
      return this.http.put(`${this.apiURL}users/${id}`, params)
        .pipe(map(x => {
            // update stored user if the logged in user updated their own record
            if (id == this.userValue.id) {
                // update local storage
                const user = { ...this.userValue, ...params };
                localStorage.setItem('user', JSON.stringify(user));

                // publish updated user to subscribers
                this.userSubject.next(user);
            }
            return x;
        }));
  }

  delete(id: string) {
      return this.http.delete(`${this.apiURL}users/${id}`)
        .pipe(map(x => {
            // auto logout if the logged in user deleted their own record
            if (id == this.userValue.id) {
                this.logout();
            }
            return x;
        }));
  }

}
