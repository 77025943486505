import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanySettingDTO } from '@shared/dto/companysettingdto';
import { Observable } from 'rxjs';
import { EndpointDictionaryService } from './endpoint-dictionary.service';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {

  constructor(private http: HttpClient, private dictService: EndpointDictionaryService) { }

  getCompanySettings(): Observable<CompanySettingDTO[]> {
    return this.http.get<CompanySettingDTO[]>(this.dictService.getServiceBaseUrl('GetCompanySettings'));
  }

  setCompanySetting(payload: CompanySettingDTO[]): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.patch<CompanySettingDTO[]>(this.dictService.getServiceBaseUrl('SetCompanySettings'), JSON.stringify(payload),{headers});
  }


}
