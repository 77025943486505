import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, DialogPosition} from '@angular/material/dialog';

@Component({
  selector: 'app-coolcalendar',
  templateUrl: './coolcalendar.component.html',
  styleUrls: ['./coolcalendar.component.css']
})
export class CoolcalendarComponent implements OnInit {
  public showCalendar = false;
  public textCalendar: string;
  public selectedYear:number = 2021;
  private calendarMinYear = 2000;
  private calendarMaxYear = 2050;
  public calendarFromDate: Date;
  public calendarToDate: Date;
  private calendarFromDateP: Date;
  private calendarToDateP: Date;
  // private anchorAlign: Align = { horizontal: "right", vertical: "bottom" };
  //private popupAlign: Align = { horizontal: "right", vertical: "top" };
  dpCalendarDal = new FormControl(moment().startOf('month').toDate());
  dpCalendarAl = new FormControl(moment().toDate());
  //---------------------
  @ViewChild('btnCalendar', { read: ElementRef }) btnCalendar: ElementRef;
  popupPosition: Position;
  @Output() DatesChanged = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {

    this.calendarFromDate = moment().startOf('month').toDate();
    this.calendarFromDateP = this.calendarFromDate;
    this.calendarToDate = moment().endOf('month').toDate();
    this.calendarToDateP = this.calendarToDate;
    this.selectedYear = this.calendarFromDate.getFullYear();
    this.setCalDescription();
    this.dpCalendarDal.setValue(this.calendarFromDate);
    this.dpCalendarAl.setValue(this.calendarToDate);

  }

  //calendar events
  public onToggleCalendar(): void {
    // let rect = this.btnCalendar.nativeElement.getBoundingClientRect();
    // let height = this.btnCalendar.nativeElement.offsetHeight;
    this.popupPosition = {} as Position;
    this.popupPosition.left = 0;//rect.right - 740;
    this.popupPosition.top = 10; //rect.bottom + 10;
    this.showCalendar = !this.showCalendar;
  }
  public setCalDescription(){
    this.textCalendar = capitalize(moment(this.calendarFromDate).format('DD MMM yyyy'),3)+' - '+capitalize(moment(this.calendarToDate).format('DD MMM yyyy'),3);
  }
  public calSelectedYearBackClick(){
    if(this.selectedYear > this.calendarMinYear){
      this.selectedYear = this.selectedYear - 1;
    }
  }
  public calSelectedYearForwardClick(){
    if(this.selectedYear < this.calendarMaxYear){
      this.selectedYear = this.selectedYear + 1;
    }
  }
  onCalDalChange(event): void {
    this.calendarFromDate = event.value;
    this.calendarFromDateP = this.calendarFromDate;
  }
  onCalAlChange(event): void {
    this.calendarToDate = event.value;
    this.calendarToDateP = this.calendarToDate;
  }
  public btnCalCancel(){
    this.showCalendar =false;
  }
  public btnCalConferma(){
    //this.calendarFromDate = this.calendarFromDateP;
    //this.calendarToDate = this.calendarToDateP;
    this.calendarFromDate = this.dpCalendarDal.getRawValue();
    this.calendarToDate = this.dpCalendarAl.getRawValue();

    this.setCalDescription();
    this.showCalendar = false;
    this.DatesChanged.emit(this.datesToString());
  }
  public calSetPeriod(pPeriod: string){
    console.log(pPeriod);
    switch(pPeriod){
      case '1T':
        this.calendarFromDate = new Date(this.selectedYear,0,1);
        this.calendarToDate = moment(this.calendarFromDate).add(3,'months').subtract(1,'day').toDate();
        break;
      case '2T':
        this.calendarFromDate = new Date(this.selectedYear,3,1);
        this.calendarToDate = moment(this.calendarFromDate).add(3,'months').subtract(1,'day').toDate();
        break;
      case '3T':
        this.calendarFromDate = new Date(this.selectedYear,6,1);
        this.calendarToDate = moment(this.calendarFromDate).add(3,'months').subtract(1,'day').toDate();
        break;
      case '4T':
        this.calendarFromDate = new Date(this.selectedYear,9,1);
        this.calendarToDate = moment(this.calendarFromDate).add(3,'months').subtract(1,'day').toDate();
        break;
      case 'A'://tutto l'anno
          this.calendarFromDate = new Date(this.selectedYear,0,1);
          this.calendarToDate = new Date(this.selectedYear,11,31);
          break;

      default:
          this.calendarFromDate = new Date(this.selectedYear,parseInt(pPeriod),1);
          this.calendarToDate = moment(this.calendarFromDate).add(1,'months').subtract(1,'day').toDate();
          break;
    }
    this.dpCalendarDal.setValue(this.calendarFromDate);
    this.dpCalendarAl.setValue(this.calendarToDate);
    this.setCalDescription();
    this.showCalendar = false;
    this.DatesChanged.emit(this.datesToString());
  }
  public setDalAl(){
    this.dpCalendarDal.setValue(this.calendarFromDate);
    this.dpCalendarAl.setValue(this.calendarToDate);
  }
  private datesToString(): string {
    return moment(this.calendarFromDate).format('yyyy MM DD')+';'+ moment(this.calendarToDate).format('yyyy MM DD');
  }
}

interface Position {
	left: number;
	top: number;
}
export function capitalize(pValue: string,pIndex: number): string {
  if (typeof pValue !== 'string') return ''
  else{
    return pValue.slice(0,pIndex) + pValue.charAt(pIndex).toUpperCase() + pValue.slice(pIndex+1);
  }
}
