<div class="customizer-handle" matTooltip="Drag me around"
    cdkDrag (cdkDragStarted)="handleDragStart($event)">
  <button mat-mini-fab color="primary" (click)="openPanel($event)">
    <mat-icon aria-label="Example icon-button with a settings icon">settings</mat-icon>
  </button>
</div>

<div [ngClass]="{ 'customizer-panel': true, 'mat-elevation-z12 opened': opened }">
  <h2 fxLayout="row" fxLayoutAlign="space-between center" class="f-w-400">
    <span>Configuration</span>
    <button mat-icon-button (click)="closePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  <h3 class="f-w-400">Theme</h3>
  <div class="m-l-8">
    <mat-radio-group [(ngModel)]="options.theme" (change)="sendOptions()">
      <mat-radio-button class="m-r-8" [value]="'light'">Light</mat-radio-button>
      <mat-radio-button class="m-r-8" [value]="'dark'">Dark</mat-radio-button>
    </mat-radio-group>
  </div>
  <h3 class="f-w-400">Header</h3>
  <div class="m-l-8">
    <mat-slide-toggle [(ngModel)]="options.showHeader"
                      (change)="sendOptions()"
                      [disabled]="options.headerPos === 'above' || options.navPos === 'top'">visible
    </mat-slide-toggle>
    <h4 class="f-w-400">position</h4>
    <mat-radio-group [(ngModel)]="options.headerPos" (change)="sendOptions()">
      <mat-radio-button class="m-r-8" [value]="'above'"
                        [disabled]="!options.showHeader || options.navPos === 'top'">Above
      </mat-radio-button>
      <mat-radio-button class="m-r-8" [value]="'fixed'">Fixed</mat-radio-button>
      <mat-radio-button class="m-r-8" [value]="'static'">Static</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-divider inset class="m-x-0 m-y-24"></mat-divider>
  <h3 class="f-w-400">Navigation</h3>
  <div class="m-l-8">
    <mat-slide-toggle [(ngModel)]="options.showUserPanel" (change)="sendOptions()">Show User Panel
    </mat-slide-toggle>
    <h4 class="f-w-400">position</h4>
    <mat-radio-group [(ngModel)]="options.navPos"
                     (change)="options.sidenavOpened = options.navPos === 'side' ? true : false; sendOptions()">
      <mat-radio-button class="m-r-8" [value]="'side'">side</mat-radio-button>
      <mat-radio-button class="m-r-8" [value]="'top'"
                        [disabled]="options.headerPos === 'above' || !options.showHeader">top
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-divider inset class="m-x-0 m-y-24"></mat-divider>
  <div class="m-l-8">
    <mat-checkbox [checked]="options.dir === 'rtl'"
                  (change)="options.dir = options.dir === 'rtl' ? 'ltr' : 'rtl'; sendOptions()">RTL
    </mat-checkbox>
  </div>
</div>
<div [ngClass]="{ 'customizer-panel-overlay': true, 'opened': opened }" (click)="closePanel()">
</div>

