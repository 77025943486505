import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'page-header',
  host: {
    class: 'matero-page-header',
  },
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() nav: string[] = [];

  constructor() {}

  ngOnInit() {

    this.title = this.title || this.nav[this.nav.length - 1];
  }

}
