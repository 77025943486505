export interface HomeDocumentsSummaryDTO {

  passiveDocumentsSummaryThisYear: number;
  activeDocumentSummaryThisYear: number;
  passivePaymentDuesSummaryThisYear: number;
  passiveDocumentsSummaryLastYear: number;
  activeDocumentSummaryLastYear: number;

}

export interface HomeDocumentsCountDTO {

  lastMonthPassiveCount: number;
  thisMonthPassiveCount: number;
  allTimePassiveCount: number;

  lastMonthActiveCount: number;
  thisMonthActiveCount: number;
  allTimeActiveCount: number;

  lastMonthPaymentDuesCount: number;
  thisMonthPaymentDuesCount: number;
  allTimePaymentDuesCount: number;

}

export interface HomeMonthlyPaymentDuesSummaryDTO {

  year: number;
  month: number;
  paymentDuesSummaryAmount: number;

}

export interface HomeTopCompanyDTO {

  companyId: number;
  companyName: string;
  count: number;
  totalAmount: number;

}


export interface HomeLastExecutionDTO {

  TaskName: string;
  LastExecutionDate: Date;

}
