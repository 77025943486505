import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@core/authentication/auth.service';
import { EnvironmentsService } from '@core/services/environments.service';
import {SpinnerService} from "@shared/spinner/spinner.service";
import {tap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarConfirmComponent} from "../../pages/invoices/snack-bar-components/snack-bar-confirm.component";


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  apiURL: string;
  mode: string;

  constructor(private authService: AuthService, private envService: EnvironmentsService, private spinnerService: SpinnerService) {
    // Environments Variable read
    this.envService.Environment$.subscribe(res => {
      this.apiURL = res.apiURL;
      this.mode = res.mode;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.authService.userValue;
    const isLoggedIn = user && user.token;
    const isApiUrl = request.url.startsWith(this.apiURL);
    if (isLoggedIn && isApiUrl) {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${user.token}`,
                tenant: `${user.tenant}`
            }
        });
    }

    this.spinnerService.requestStarted();
    return this.handler(next,request);
  }

  handler(next, request) {
    return next.handle(request)
      .pipe(
        tap(
          (event) => {
                  if (event instanceof  HttpResponse) {
                    this.spinnerService.requestEnded();
                  }
          },
          (error: HttpResponse<any>) => {
                  this.spinnerService.resetSpinner();
                  throw error;
          }
        )
      );
  }

}
