<button  mat-raised-button #btnCalendar (click)="onToggleCalendar()" class="calendar-main-button">
    <span>{{textCalendar}} </span>
    <fa-icon [icon]="['far', 'caret-down']" class=""></fa-icon>
</button>
<div *ngIf="showCalendar" style="position: relative;">
    <div class="calendar-content" style="position: absolute;" [style.left.px]="popupPosition.left" [style.top.px]="popupPosition.top">
        <div class="row">
            <div class="col-lg-12">
              <div style="margin-left: auto;margin-right: auto;width: fit-content;padding-bottom: 20px;">
              <button #btnCalendarYearBack class="calendar-button" (click)="calSelectedYearBackClick()">
                <fa-icon [icon]="['far', 'caret-left']" class=""></fa-icon>
              </button>
              <span class="calendar-current-year">Anno {{ selectedYear }}</span>
              <button #btnCalendarYearForward class="calendar-button" (click)="calSelectedYearForwardClick()" >
                <fa-icon [icon]="['far', 'caret-right']" class=""></fa-icon>
              </button>
            </div>
            </div>
          </div>
          <div class="row calendar-row">
            <div class="vl" style="left:33.33333333%;"></div>
            <div class="vl"></div>
            <div class="vl" style="left:66.66666667%;"></div>
            <div class="vl" style="left:83.33333333%;"></div>
            <div class="col-lg-4">
              <button (click)="calSetPeriod('A')" class="lk-button calendar-item pal20"><span class="link-period-calendar">Tutto l'anno</span></button>
            </div>
            <div class="col-lg-2">
              <button class="lk-button calendar-item pal0" (click)="calSetPeriod('1T')"><span class="link-period-calendar">1° Trimestre</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('0')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Gennaio</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('4')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Maggio</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('8')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Settembre</span></button>
            </div>
          </div>
          <div class="row pab15">
            <div class="col-lg-4 mat15less" style="padding-left: 10px;">
              <mat-form-field appearance="fill">
                <mat-label>Dal</mat-label>
                <input matInput [matDatepicker]="picker" (dateChange)="onCalDalChange($event)" [formControl]="dpCalendarDal">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('2T')" class="lk-button calendar-item pal0"><span class="link-period-calendar">2° Trimestre</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('1')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Febbraio</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('5')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Giugno</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('9')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Ottobre</span></button>
            </div>
          </div>
          <div class="row pab15">
            <div class="col-lg-4 mat15less" style="padding-left: 10px;">
              <mat-form-field appearance="fill">
                <mat-label>Al</mat-label>
                <input matInput [matDatepicker]="pickerAl" (dateChange)="onCalAlChange($event)" [formControl]="dpCalendarAl">
                <mat-datepicker-toggle matSuffix [for]="pickerAl"></mat-datepicker-toggle>
                <mat-datepicker #pickerAl></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('3T')" class="lk-button calendar-item pal0"><span class="link-period-calendar">3° Trimestre</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('2')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Marzo</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('6')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Luglio</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('10')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Novembre</span></button>
            </div>
          </div>
          <div class="row calendar-row">
            <div class="col-lg-4">
              <button (click)="btnCalConferma()" class="lk-button calendar-item pal20">Conferma</button>
              <button (click)="btnCalCancel()" class="lk-button calendar-item pal30">Annulla</button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('4T')" class="lk-button calendar-item pal0"><span class="link-period-calendar">4° Trimestre</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('3')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Aprile</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('7')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Agosto</span></button>
            </div>
            <div class="col-lg-2">
              <button (click)="calSetPeriod('11')" class="lk-button calendar-item pal0"><span class="link-period-calendar">Dicembre</span></button>
            </div>
          </div>


    </div>
</div>
