<div class="w-full h-full" fxLayout="row wrap">
  <mat-card class="mat-elevation-z4 m-auto" style="max-width: 380px;">
    <mat-card-title class="text-center m-b-24">Invoice Manager</mat-card-title>

    <form class="form-field-full" [formGroup]="loginForm">

      <mat-form-field appearance="outline">
        <mat-label>Username:</mat-label>
        <input matInput placeholder="Username" formControlName="username" required>
        <mat-error *ngIf="username.invalid">
          <span *ngIf="username.errors?.required">
            Si prega di inserire il nome dell`Usuario<strong>ng-matero</strong>
          </span>

          <span *ngIf="username.errors?.remote">
            {{ username.errors?.remote }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Password: </mat-label>
        <input matInput placeholder="password" type="password"
               formControlName="password" required>
        <mat-error *ngIf="password.invalid">
          <span *ngIf="password.errors?.required">
            Si prega di inserire una <strong>password</strong>
          </span>
          <span *ngIf="password.errors?.minLength">
            Si prega di inserire una password di al meno 6 caratteri<strong>ng-matero</strong>
          </span>

          <span *ngIf="password.errors?.remote">
            {{ password.errors?.remote }}
          </span>
        </mat-error>
      </mat-form-field>

      <!-- <div class="m-y-16" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox formControlName="rememberMe">Ricordami
        </mat-checkbox>
      </div> -->

      <button class="w-full" mat-raised-button color="primary"
              [disabled]="!!loginForm.invalid" [class.spinner]="isSubmitting"
              (click)="login()">Login</button>

      <!-- <div class="m-t-16">{{'login.have_no_account' | translate}}?
        <a routerLink="/auth/register">{{'login.create_one' | translate}}</a>
      </div> -->
    </form>

  </mat-card>
</div>
